import { useState, useRef, useEffect, Fragment } from 'react'
import { YMInitializer } from 'react-yandex-metrika'
import InputMask from 'react-input-mask'
import { DateTime } from 'luxon'
import './App.css'
import useAxios from 'axios-hooks'
import Logo3x from './assets/logo.png'
// import PrivacyPolicy from "./assets/privacy_policy.pdf"
import AppleWalletLogo from './assets/Add_to_Apple_Wallet_rgb_RU.svg'
import GoogleWalletLogo from './assets/ru_add_to_google_wallet_add-wallet-badge.svg'

const timerLength = 180 // seconds
const uri = 'https://api.parfum-atelier.com'

class ResponseError extends Error {
    constructor(message, status, errMessage) {
        super(message)
        this.name = 'ResponseError'
        this.status = status
        this.errMessage = errMessage
    }
}

const App = () => {
    const inputPhoneRef = useRef(null)
    const timerRef = useRef(null)
    const codeRef = useRef(null)
    const notificationRef = useRef(null)
    const submitRef = useRef(null)
    const afterSignup = useRef(null)
    const codeBtnRef = useRef(null)
    const smsBlockRef = useRef(null)
    const beforeUserCheckRef = useRef(null)
    const afterUserCheckRef = useRef(null)
    const afterUserCheckRadiosRef = useRef(null)
    const smsInputBlockRef = useRef(null)

    const [time, setTime] = useState(timerLength)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [dob, setDob] = useState('')
    const [phone, setPhone] = useState('')
    const [sex, setSex] = useState('female')
    const [code, setCode] = useState('')
    const [disclaimer, setDisclaimer] = useState(true)
    const [signupIsEnabled, setSignupIsEnabled] = useState(false)
    const [token, setToken] = useState('')
    const [appleWallet, setAppleWallet] = useState('')
    const [googleWallet, setGoogleWallet] = useState('')
    const [isProcessing, setIsProcessing] = useState(false)

    const [callPhone, setCallPhone] = useState('')
    const [isClicked, setWasClicked] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    // const [card, setCard] = useState("")

    const parsePhone = (phone) => {
        let p = phone.replace(/^\+|[\s_()-]/g, '')
        if (p.charAt(0) === '8') p = p.substring(0, 0) + '7' + p.substring(1)
        return p
    }
    const parseCode = (code) => code.replace(/_/g, '')

    const [{ data, loading, error }, verify] = useAxios(
        {
            url: 'https://api.parfum-atelier.com/v2/auth/verify',
            method: 'POST',
        },
        { manual: true }
    )

    useEffect(() => {
        if (phone.charAt(4) === '7' || phone.charAt(4) === '8' || phone.charAt(4) === '+') {
            if (inputPhoneRef && inputPhoneRef.current) {
                if (inputPhoneRef.current.setCursorPosition) {
                    inputPhoneRef.current.setCursorPosition(4)
                    setPhone('+7 (___) ___-__-__')
                }
            }
            return
        }

        const p = parsePhone(phone)
        if (codeBtnRef && codeBtnRef.current) {
            codeBtnRef.current.disabled = p.length !== 11
        }
    }, [phone])

    useEffect(() => {
        if (data) {
            setSuccess(true)
            const { token, error } = data
            setToken(token)
            codeRef.current.classList.add('success')
            if (error === 'customer are not registered') {
                if (smsBlockRef.current) {
                    smsBlockRef.current.classList.add('form__inputs--hidden')
                }
                if (afterUserCheckRef.current) {
                    afterUserCheckRef.current.classList.remove('form__inputs--hidden')
                }
                if (afterUserCheckRadiosRef.current) {
                    afterUserCheckRadiosRef.current.classList.remove('form__inputs--hidden')
                }
                setCallPhone('')
                setSignupIsEnabled(true)
            } else {
                getProfile(token)
            }
        }
    }, [data])

    useEffect(() => {
        const c = parseCode(code)
        if (codeRef) {
            if (c.length === 5) {
                const p = parsePhone(phone)
                codeRef.current.disabled = true

                const url = `${uri}/v2/auth/login`

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phone: p, code: c, type: 'SMS' }),
                })
                    .then((res) => {
                        if (!res.ok) throw new ResponseError(res.statusText, res.status)
                        return res.json()
                    })
                    .then(({ token }) => {
                        setToken(token)
                        codeRef.current.classList.add('success')

                        if (token) {
                            getProfile(token)
                        }
                    })
                    .catch((err) => {
                        if (err instanceof ResponseError) {
                            if (err.status === 403) {
                                fetch(`${uri}/v1/auth/confirmPhone`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ phone: p, code: c }),
                                })
                                    .then((res) => {
                                        if (!res.ok)
                                            throw new ResponseError(res.statusText, res.status)
                                        return res.json()
                                    })
                                    .then(({ token }) => {
                                        setToken(token)
                                        codeRef.current.classList.add('success')

                                        if (smsBlockRef.current) {
                                            smsBlockRef.current.classList.add(
                                                'form__inputs--hidden'
                                            )
                                        }
                                        if (afterUserCheckRef.current) {
                                            afterUserCheckRef.current.classList.remove(
                                                'form__inputs--hidden'
                                            )
                                        }
                                        if (afterUserCheckRadiosRef.current) {
                                            afterUserCheckRadiosRef.current.classList.remove(
                                                'form__inputs--hidden'
                                            )
                                        }
                                        setSignupIsEnabled(true)
                                    })
                                    .catch((err) => {
                                        if (err instanceof ResponseError) {
                                            console.log(err)
                                        }
                                    })
                            }

                            if (err.status === 401) {
                                codeRef.current.classList.add('error')
                                codeRef.current.disabled = false
                            }
                        }
                    })
            }
        }
    }, [code])

    useEffect(() => {
        let interval

        if (callPhone) {
            interval = setInterval(async () => {
                if (isSuccess) clearInterval(interval)
                try {
                    await verify({
                        data: {
                            phone: parsePhone(phone),
                            type: 'CALL',
                        },
                    })
                } catch (e) {
                    console.log('Pupa')
                }
            }, 2000)
        }

        return () => {
            clearInterval(interval)
        }
    }, [callPhone, isSuccess])

    const getRegistrationToken = () => {}

    const setTypeSMS = (p, btn) => {
        if (smsInputBlockRef.current) {
            smsInputBlockRef.current.classList.remove('form__inputs--hidden')
        }
        codeRef.current.disabled = false

        const url = `${uri}/v2/auth/login`
        setCallPhone('')
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone: p, type: 'SMS' }),
        })
            .then((res) => {
                if (res.status !== 201) throw new Error("Can't send a request")
                return res.json()
            })
            .then((data) => {
                if (timerRef) {
                    timerRef.current.classList.remove('form__timer--hidden')
                    let a = timerLength

                    const t = setInterval(() => {
                        if (a > 0) {
                            a -= 1
                        } else {
                            clearInterval(t)
                            btn.disabled = false
                            a = timerLength
                            timerRef.current.classList.add('form__timer--hidden')
                        }
                        setTime(a)
                    }, 1000)
                }
                if (smsInputBlockRef.current) {
                    smsInputBlockRef.current.classList.remove('form__inputs--hidden')
                }
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    const onSendCodeClick = (e) => {
        e.preventDefault()
        const p = parsePhone(phone)
        if (p.length !== 11) return
        const btn = e.target
        btn.disabled = true
        btn.innerHTML = 'Выслать смс-код'

        if (isClicked) {
            setTypeSMS(p, btn)
            return
        }
        setWasClicked(true)

        // if (smsInputBlockRef.current) {
        //     smsInputBlockRef.current.classList.remove('form__inputs--hidden')
        // }

        const url = `${uri}/v2/auth/login`

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone: p, type: 'CALL' }),
        })
            .then((res) => {
                if (res.status !== 201) throw new Error("Can't send a request")
                return res.json()
            })
            .then((data) => {
                if (timerRef) {
                    timerRef.current.classList.remove('form__timer--hidden')
                    let a = data.waitTime
                    setCallPhone(data.phone)
                    const t = setInterval(() => {
                        if (a > 0) {
                            a -= 1
                        } else {
                            clearInterval(t)
                            btn.disabled = false
                            a = timerLength
                            timerRef.current.classList.add('form__timer--hidden')
                        }
                        setTime(a)
                    }, 1000)
                }
                // if (smsInputBlockRef.current) {
                //     smsInputBlockRef.current.classList.remove('form__inputs--hidden')
                // }
            })
            .catch((err) => {
                console.log(err.message)
            })
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    }

    const onSubmit = (e) => {
        e.preventDefault()

        setIsProcessing(true)

        notificationRef.current.innerHTML = ''
        const errClass = notificationRef.current.classList.contains('form__notification--error')
        const dateDob = DateTime.fromFormat(dob, 'dd.LL.y')

        if (dateDob >= DateTime.now()) {
            notificationRef.current.innerHTML = 'Необходимо указать правильную дату рождения'
            if (!errClass) notificationRef.current.classList.add('form__notification--error')
            setIsProcessing(false)
            return
        }

        if (notificationRef) {
            if (firstName.length < 2) {
                notificationRef.current.innerHTML = 'Необходимо указать имя'
                if (!errClass) notificationRef.current.classList.add('form__notification--error')
                setIsProcessing(false)
                return
            }

            if (lastName.length < 2) {
                notificationRef.current.innerHTML = 'Необходимо указать фамилию'
                if (!errClass) notificationRef.current.classList.add('form__notification--error')
                setIsProcessing(false)
                return
            }

            if (email.length > 0 && !validateEmail(email)) {
                notificationRef.current.innerHTML = 'E-mail указан неправильно'
                if (!errClass) notificationRef.current.classList.add('form__notification--error')
                setIsProcessing(false)
                return
            }

            if (!dateDob.isValid) {
                notificationRef.current.innerHTML = 'Необходимо указать дату рождения'
                if (!errClass) notificationRef.current.classList.add('form__notification--error')
                setIsProcessing(false)
                return
            }

            if (dateDob.year < 1920) {
                notificationRef.current.innerHTML = 'Необходимо указать год рождения > 1900'
                if (!errClass) notificationRef.current.classList.add('form__notification--error')
                setIsProcessing(false)
                return
            }
        }

        submitRef.current.disabled = true

        fetch(`${uri}/v1/auth/registration`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
                firstName,
                lastName,
                sex,
                birthDate: dateDob.toFormat('y-LL-dd'),
                restrictions: { sms: true, email: true, push: true },
                refCustomerPhone: undefined,
                email: email !== '' ? email : undefined,
            }),
        })
            .then((res) => {
                if (!res.ok) throw new ResponseError(res.statusText, res.status, res.json())
                return res.json()
            })
            .then(({ appleWallet: aw, googleWallet: gw }) => {
                if (aw) setAppleWallet(aw)
                if (gw) setGoogleWallet(gw)
                afterSignup.current.classList.add('form__after-signup--block')
            })
            .catch((err) => {
                if (err instanceof ResponseError) {
                    if (err.status === 500) {
                        notificationRef.current.innerHTML =
                            'Произошла ошибка на сервере. Пожалуйста, попробуйте зарегистрироваться позже'
                        if (!errClass)
                            notificationRef.current.classList.add('form__notification--error')
                    } else if (err.status === 400) {
                        Promise.resolve(err.errMessage).then(({ message }) => {
                            if (
                                message.includes('refCustomerPhone must be a valid phone number') ||
                                message === 'ref customer not found'
                            ) {
                                notificationRef.current.innerHTML = 'Телефон реферала не найден'
                                if (!errClass)
                                    notificationRef.current.classList.add(
                                        'form__notification--error'
                                    )
                            }
                        })
                    } else if (err.status === 401) {
                        Promise.resolve(err.errMessage).then(({ message }) => {
                            if (message === 'phone is already in use') {
                                notificationRef.current.innerHTML =
                                    'Данный телефон уже зарегистрирован'
                                if (!errClass)
                                    notificationRef.current.classList.add(
                                        'form__notification--error'
                                    )
                            }
                        })
                    }
                }
                setIsProcessing(false)
                submitRef.current.disabled = false
            })
    }

    const getProfile = (token) => {
        fetch(`${uri}/v1/customer/profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (!res.ok) throw new ResponseError(res.statusText, res.status, res.json())
                return res.json()
            })
            .then(({ wallet: { card: aw }, googleWallet: gw }) => {
                if (aw) setAppleWallet(aw)
                if (gw) setGoogleWallet(gw)
                afterSignup.current.classList.add('form__after-signup--block')
            })
            .catch((err) => {
                console.log(err)
                notificationRef.current.innerHTML =
                    'Произошла ошибка на сервере. Пожалуйста, попробуйте зарегистрироваться позже'
                notificationRef.current.classList.add('form__notification--error')
            })
    }

    const parseTime = (time) => {
        let minute = Math.floor(time / 60)
        let seconds = time % 60

        if (minute < 10) minute = `0${minute}`

        if (seconds < 10) seconds = `0${seconds}`

        return `${minute}:${seconds}`
    }

    const onPaste = (e, setFunc) => {
        e.stopPropagation()
        e.preventDefault()

        const clipboardData = e.clipboardData || window.clipboardData
        const pastedData = clipboardData.getData('Text')

        setFunc(parsePhone(pastedData))
    }

    return (
        <main className='main'>
            <YMInitializer
                accounts={[90648190]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true,
                }}
                version='2'
            />

            <div className='main__logo--wrapper'>
                <img src={Logo3x} alt='Parfumer Atelier' className='main__logo' />
            </div>
            <div className='main__description'></div>
            <section className='main__form'>
                <div className='main__form__background' />
                <form className='form' onSubmit={onSubmit}>
                    <h1 className='form__title'>Вход</h1>
                    {/* <p>Мы отправим на номер SMS-сообщение с кодом подтверждения.</p> */}
                    <div ref={beforeUserCheckRef} className='form__inputs form__inputs--center'>
                        <div className='form__input'>
                            <label>Телефон</label>
                            <InputMask
                                ref={inputPhoneRef}
                                onPaste={(e) => onPaste(e, setPhone)}
                                mask='+7 (999) 999-99-99'
                                autoComplete='tel'
                                name='phone'
                                value={phone}
                                onChange={({ target: { value } }) => setPhone(value)}
                            />
                        </div>

                        {callPhone && (
                            <>
                                <p style={{ margin: 0 }}>
                                    Позвоните на номер{' '}
                                    <a href={`tel:${callPhone}`}>
                                        <b>{callPhone}</b>
                                    </a>
                                </p>
                                <small>
                                    Звонок бесплатный, подтверждение пройдет автоматически
                                </small>
                            </>
                        )}

                        <div className='form__input form__inputs--hidden' ref={smsInputBlockRef}>
                            <label>СМС-код</label>
                            <input
                                type='text'
                                name='code'
                                autoComplete='one-time-code'
                                value={code}
                                onChange={({ target: { value } }) => setCode(value)}
                                ref={codeRef}
                                disabled
                            />
                        </div>

                        <div className='form__input--button' ref={smsBlockRef}>
                            <p className='form__timer form__timer--hidden' ref={timerRef}>
                                {parseTime(time)}
                            </p>
                            <button
                                type='button'
                                onClick={onSendCodeClick}
                                className='form__button'
                                ref={codeBtnRef}>
                                Войти
                            </button>
                        </div>
                    </div>

                    <div ref={afterUserCheckRef} className='form__inputs form__inputs--hidden'>
                        <div className='form__input'>
                            <label>Имя</label>
                            <input
                                type='text'
                                name='firstName'
                                value={firstName}
                                onChange={({ target: { value } }) => setFirstName(value)}
                            />
                        </div>

                        <div className='form__input'>
                            <label>Фамилия</label>
                            <input
                                type='text'
                                name='lastName'
                                value={lastName}
                                onChange={({ target: { value } }) => setLastName(value)}
                            />
                        </div>

                        <div className='form__input'>
                            <label>Дата рождения</label>
                            <InputMask
                                mask='99.99.9999'
                                name='dob'
                                value={dob}
                                onChange={({ target: { value } }) => setDob(value)}
                            />
                        </div>

                        <div className='form__input'>
                            <label>E-mail</label>
                            <input
                                type='email'
                                name='email'
                                value={email}
                                onChange={({ target: { value } }) => setEmail(value)}
                            />
                        </div>
                    </div>

                    <div
                        ref={afterUserCheckRadiosRef}
                        className='form__input--radios form__inputs--hidden'>
                        <div className='form__radio'>
                            <input
                                type='radio'
                                id='form-sex-female'
                                name='sex'
                                value='female'
                                onChange={({ target: { value } }) => setSex(value)}
                                defaultChecked
                            />
                            <label htmlFor='form-sex-female'>Женщина</label>
                        </div>

                        <div className='form__radio'>
                            <input
                                type='radio'
                                id='form-sex-male'
                                name='sex'
                                value='male'
                                onChange={({ target: { value } }) => setSex(value)}
                            />
                            <label htmlFor='form-sex-male'>Мужчина</label>
                        </div>
                    </div>

                    {signupIsEnabled ? (
                        <Fragment>
                            <div className='form__disclaimer'>
                                <div className='form__checkbox'>
                                    <input
                                        type='checkbox'
                                        id='disclaimer'
                                        name='disclaimer'
                                        defaultChecked
                                        onChange={() => setDisclaimer(!disclaimer)}
                                    />
                                    <label htmlFor='disclaimer'>
                                        Я соглашаюсь с{' '}
                                        <a href={'#'} target='_blank' rel='noopener noreferrer'>
                                            Политикой конфиденциальности
                                        </a>
                                    </label>
                                </div>
                            </div>

                            <button
                                type='submit'
                                className='form__submit'
                                ref={submitRef}
                                disabled={!disclaimer}>
                                {isProcessing ? (
                                    <div className='lds-dual-ring' />
                                ) : (
                                    <span>Зарегистрироваться</span>
                                )}
                            </button>
                        </Fragment>
                    ) : null}

                    <p className='form__notification' ref={notificationRef} />

                    <div className='form__after-signup' ref={afterSignup}>
                        <h3 className='form__card-number'>Добавьте карту в кошелёк</h3>
                        <a href={appleWallet} target='_blank' rel='noreferrer nofollow noopener'>
                            <img
                                src={AppleWalletLogo}
                                alt='Добавить в Apple Wallet'
                                style={{ height: '3rem', width: 'auto' }}
                            />
                        </a>
                        <a
                            href={`https://pay.google.com/gp/v/save/${googleWallet}`}
                            target='_blank'
                            rel='noreferrer nofollow noopener'>
                            <img
                                src={GoogleWalletLogo}
                                alt='Добавить в Google Wallet'
                                style={{ height: '3rem', width: 'auto' }}
                            />
                        </a>
                    </div>
                </form>
            </section>
        </main>
    )
}

export default App
